export const theRoleOfInvestments = {
  order: 0,
  name: "The role of investments",
  keywords:
    "deal comp sale refinance loan own buy sell value price capital stack provenance custody timeline portfolio",
  subcategory: "Investments",
  markdown: `# The role of investments

  In Tower Hunt, investments are transactions that affect the ownership of assets (ex. property rights, loans). Investments create important connections between {% inlineRouterLink articleId="what-is-a-property?" %}properties{% /inlineRouterLink %}, companies, and contacts. They also provide context about the value of properties over time. If you are using Tower Hunt to find sale comps or discover who owns a building, then you are interacting with investments. Read on to learn more about how they work in Tower Hunt.

  {% callout type="tip" %}
  **Tip:** Because of {% inlineRouterLink articleId="what-is-a-property?" %}the role of properties in Tower Hunt{% /inlineRouterLink %}, investments work hand-in-hand with {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}the property diagram{% /inlineRouterLink %}. It's especially important to understand where {% inlineRouterLink articleId="visual-features-of-the-property-diagram" %}property rights are displayed in the diagram{% /inlineRouterLink %} so that you can connect investments to the right assets.
  {% /callout %}

  ## What to expect

  - Nearly all investment {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoints{% /inlineRouterLink %} are {% inlineAppIcon iconName="private" /%}{% inlineRouterLink articleId="what-is-safezone?" %}Safezone{% /inlineRouterLink %} by default.
  - Many investments are at least partially {% inlineRouterLink articleId="declassify-datapoints" %}declassified{% /inlineRouterLink %} over time.
  - This means that Tower Hunt users have **different levels of visibility** into the investment activity in their respective markets.

  ## Flexibility

  Tower Hunt caters to the inherent fragmentation of real estate data: a given user likely has complete knowledge of a small slice of the market and incomplete knowledge of the rest.

  ### Investment creation

  To {% inlineRouterLink articleId="create-an-investment" %}create an investment{% /inlineRouterLink %}, all you need is a status (ex. live, under contract, closed) **or** an involved company. Dozens of details can be added incrementally &mdash; and by multiple users &mdash; as they become known.

  ### Portfolios

  In commercial real estate, multiple assets commonly transact as part of a single selling process. These so-called {% inlineRouterLink articleId="create-a-portfolio-investment" %}portfolio investments{% /inlineRouterLink %} often involve nuances that are loaded with context, such as when individual assets sell to different buyers on different timelines. Tower Hunt is built to capture these details so you can see the full picture.

  ## Provenance

  In Tower Hunt, investments act as links in the *chain of custody* of a given asset. This helps Tower Hunt maintain a chronological list of owners of the asset, a concept known as *provenance*. When users add investments, Tower Hunt manages the opening and closing of ownership positions and loans in the background. This simplifies the user experience while also increasing asset provenance accuracy.

  {% callout type="tip" %}
  **Tip:** You can {% inlineRouterLink articleId="time-travel" %}time travel{% /inlineRouterLink %} an asset's provenance on the {% inlineRouterLink articleId="timeline" %}timeline{% /inlineRouterLink %}.
  {% /callout %}

  ## The capital stack

  Investments help Tower Hunt display an asset's {% inlineRouterLink articleId="understand-the-capital-stack" %}capital stack{% /inlineRouterLink %} at different points in time. The capital stack can help you identify all of the players and how they are related.

  ![Intro capital stack screenshot](https://assets.towerhunt.com/site/capitalStackLayers0.png)

  ## Next steps

  With this in mind, you're ready to get started with investments:

  - Learn how to {% inlineRouterLink articleId="create-an-investment" %}create an investment{% /inlineRouterLink %}.
  - Dive deeper into the {% inlineRouterLink articleId="understand-the-capital-stack" %}capital stack{% /inlineRouterLink %} and how it can help you.
  - Get introduced to the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}, which is your primary tool for modeling the property rights that investments affect.`,
};
